<template>
  <cks-combined-route @active="refreshActive">
    <cks-page-wrap style="padding-bottom: 100px;">
      <cks-combined-table :disableDown="true" :row-style="setStyle" :isTableSearch="true" ref="table" cacheKey="testcache" @load="load"
        @selection-change="handleSelectionChange" @refreshConditions="refreshConditions">
        <template #btns>
          <el-button type="primary" @click="$router.push({ name: 'classicCasesEdit'})">新建</el-button>
          <!-- <el-button type="danger" plain :disabled="!selected.length" @click="handleDelete" :loading="deleting">删除</el-button> -->
        </template>
        <!-- <el-table-column :selectable="selectable" type="selection" fixed="left"></el-table-column> -->
        <cks-table-column
          stringify
          v-model="conditions"
          @search="handleSearch"
          :tableHeaders="tableHeaders"
          @changeRouter="changeRouter"
          :tableData="tableData"
          :tableOptions="tableOptions"
          v-if="refreshTableHearder"
        >
          <template #briefText="{row}">
            <div class="text-overflow-eli" v-html="row.briefText"></div>
          </template>
          <template #titlePic="{row}">
            <el-image
              v-if="row.titlePic"
              class="show-image"
              :src="row.titlePic"
              :zoom-rate="1.2"
              :preview-src-list="[row.titlePic]"
              :initial-index="1"
              fit="cover"
            />
            <span v-else></span>
          </template>
          <template #status="{row, index}">
            <el-switch
              v-loading="row.switchLoading"
              v-if="index > 0"
              v-model="row.status"
              active-value="Y"
              inactive-value="N"
              active-text="有效"
              inactive-text="失效"
              @change="changeSwitch(row.status, row)"
              style="--el-switch-on-color: #2F51FF;"
            >
            </el-switch>
          </template>
          <template #none="{row}">
            <el-link @click="up(row.id)" class="sty-right" type="primary">上移</el-link>
            <el-link @click="down(row.id)" class="sty-right" type="success">下移</el-link>
            <el-link @click="first(row.id)" class="sty-right" type="warning">置顶</el-link>
            <el-link @click="last(row.id)" class="sty-right" type="danger">置底</el-link>
          </template>
        </cks-table-column>
      </cks-combined-table>
    </cks-page-wrap>
  </cks-combined-route>
</template>

<script>
import { createTableProps, showSuccess } from '@/utils';
import {
  toRefs, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import apis from '@/request/apis';

export default {
  setup() {
    const getDict = () => ({
      value: {
        options: [
          {
            label: '0',
            name: '星级酒店',
          },
          {
            label: '1',
            name: '冷链物流',
          },
          {
            label: '2',
            name: '中央厨房',
          },
          {
            label: '3',
            name: '民生工程',
          },
          {
            label: '4',
            name: '酒管冷链',
          },
          {
            label: '5',
            name: '文旅冷链',
          },
          {
            label: '6',
            name: '冷链智慧维保',
          },
        ],
      },
    });
    const getDict2 = () => ({
      value: {
        options: [
          {
            label: 'Y',
            name: '有效',
          },
          {
            label: 'N',
            name: '失效',
          },
        ],
      },
    });
    const data = reactive({
      loading: false,
      conditions: [],
      tableOptions: {
        titlePic: {
          isSlotKey: true,
          disableForTableSearch: true,
        },
        briefText: {
          isSlotKey: true,
          disableForTableSearch: true,
        },
        caseTypeDesc: {
          editTrasferKey: 'caseType',
          dict: getDict,
        },
        status: {
          editTrasferKey: 'status',
          isSlotKey: true,
          dict: getDict2,
        },
        none: {
          disableForTableSearch: true,
          isSlotKey: true,
          fixed: true,
          minWidth: '250px',
        },
      },
      tableHeaders: [
        {
          prop: 'name', label: '案例名称', selected: true, required: true,
        },
        {
          prop: 'status', label: '状态', selected: true, required: false,
        },
        {
          prop: 'caseTypeDesc', label: '案例类型', selected: true, required: false,
        },
        {
          prop: 'briefText', label: '案例介绍', selected: true, required: false,
        },
        {
          prop: 'titlePic', label: '标题图片', selected: true, required: false,
        },
        {
          prop: 'none', label: '操作', selected: true, required: false,
        },
      ],
    });

    const tableProps = createTableProps({
      loadApi: apis.classicCases.list,
      params: () => ({ conditions: data.conditions }),
      refreshConditions: () => { data.conditions = []; },
    });

    const router = useRouter();
    function changeRouter(row) {
      router.push({
        name: 'classicCasesEdit',
        params: {
          id: row.id,
        },
      });
    }

    const changeSwitch = async (val, row) => {
      try {
        row.switchLoading = true;
        await apis.classicCases.changeStatus({
          id: row.id,
          status: val,
        });
        showSuccess('更新成功');
      } finally {
        row.switchLoading = false;
      }
    };

    const up = async (id) => {
      try {
        await apis.classicCases.up({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };
    const down = async (id) => {
      try {
        await apis.classicCases.down({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };
    const first = async (id) => {
      try {
        await apis.classicCases.first({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };
    const last = async (id) => {
      try {
        await apis.classicCases.last({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };

    return {
      ...toRefs(data),
      ...toRefs(tableProps),
      changeRouter,
      changeSwitch,
      up,
      down,
      first,
      last,
    };
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  --el-input-focus-border: #2F51FF;
}
::v-deep .el-switch__label.is-active {
  color: #2F51FF;
}
.text-overflow-eli {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 50px;
}
.show-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}
.sty-right {
  margin-right: 20px;
}
</style>
